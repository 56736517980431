.debug1 {
  border: 1px dashed red;
}

.debug2 {
  border: 1px dashed gold;
}

@media screen and (max-width: 568px) {
  .small  { display: inline; }
  .medium { display: none;   }
  .large  { display: none;   }
}

@media screen and (min-width: 568px) and (max-width: 768px) {
  .small  { display: inline; }
  .medium { display: none;   }
  .large  { display: none;   }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .small  { display: none;   }
  .medium { display: inline; }
  .large  { display: none;   }
}

@media screen and (min-width: 1024px) {
  .small  { display: none;   }
  .medium { display: none;   }
  .large  { display: inline; }
}

.nav1 {
  box-sizing: border-box;
  position: absolute;
  left: 0;
  bottom: 0;
  height: 2em;
  padding-left: 4vw;
  padding-right: 0;
  font-size: 2.5em;
  font-weight: lighter;
  text-align: center;
  cursor: pointer;
}

.nav2 {
  box-sizing: border-box;
  position: absolute;
  right: 0;
  bottom: 0;
  height: 2em;
  padding-left: 2vw;
  padding-right: 4vw;
  font-size: 2.5em;
  font-weight: lighter;
  text-align: right;
}

@keyframes background {
  0% { background-color: #f99; }
  33% { background-color: #9f9; }
  67% { background-color: #99f; }
  100% { background-color: #f99; }
}

.project {
  padding-top: 2vw;
  padding-left: 4vw;
  padding-right: 4vw;
  padding-bottom: 2vw;
}

.project-images img {
  display: block;
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 75vh;
}

.project-images figure {
  display: table;
  margin: auto;
  font-size: 1em;
  font-weight: lighter;
}

.project-image figcaption {
  text-align: right;
}

.project-header {
  margin-top: 0;
  border-bottom: 4px solid black;
  font-size: 2em;
  font-weight: lighter;
  text-align: left;
  margin-right: 2vw;
}

.project-text {
  font-size: 1.2em;
  font-weight: lighter;
  text-align: center;
  margin: auto;
  padding: 2em;
  max-width: 66%;
}

.project-text a {
  font-style: italic;
}

.about {
  padding-top: 2vw;
  padding-left: 4vw;
  padding-right: 4vw;
  padding-bottom: 2vw;
}

.about-portrait > img {
  display: block;
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 80vh;
}

.about-header {
  border-bottom: 4px solid black;
  font-size: 2em;
  font-weight: lighter;
  text-align: left;
  margin-right: 2vw;
}

.about-cv {
  font-size: 1em;
  font-weight: lighter;
  text-align: justify;
  margin: auto;
  vertical-align: middle;
  margin-right: 2vw;
}

.max-height {
  height: 100%;
}

a {
  color: black;
  line-height: 1.5;
  text-decoration: none;
}

a:hover {
  border-bottom: 4px solid black;
}

.gallery {
  padding-top: 2vw;
  padding-left: 4vw;
  padding-right: 4vw;
  padding-bottom: 2vw;
}

.noselect {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

social {
  display: block;
  font-weight: lighter;
  text-align: right;
  padding-left: 2vw;
  padding-right: 2vw;
}

social a:hover {
  border-bottom: 3px solid black;
}
